<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('defaultStoragePricing.tablePageTitle') | title" />
    <huboo-table
      id="default-storage-pricing"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :remove="isAdmin"
      @row-clicked="onRowSelected"
      @update:options="handleUpdateOptions"
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      localPagination
    >
      <template #actions>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="additionalPricingCreateButton"
          v-if="isAdmin"
        >
          {{ $t('defaultStoragePricing.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>
    <default-storage-pricing-edit-modal
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <default-storage-pricing-create-modal
      v-model="createModal"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />
  </huboo-page>
</template>

<script>
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import VersionedPriceList from '@/mixins/versionedPriceList'
import EditModal from '../DefaultStoragePricing/DefaultStoragePricingEditModal.vue'
import CreateModal from '../DefaultStoragePricing/DefaultStoragePricingCreateModal.vue'

const tableHeaders = [
  {
    text: title(i18n.t('defaultStoragePricing.fields.priceListCode')),
    sortable: false,
    value: 'priceListCode',
  },
  {
    text: title(i18n.t('defaultStoragePricing.fields.description')),
    sortable: false,
    value: 'description',
  },
  {
    text: title(i18n.t('defaultStoragePricing.fields.originRegion')),
    sortable: false,
    value: 'originRegion',
  },
  {
    text: title(i18n.t('defaultStoragePricing.fields.currency')),
    sortable: false,
    value: 'currency',
  },
  {
    text: title(i18n.t('defaultStoragePricing.fields.price')),
    sortable: false,
    value: 'price',
  },
  {
    text: title(i18n.t('defaultStoragePricing.fields.updatedAt')),
    sortable: true,
    value: 'updatedAt',
  },
]

export default {
  name: 'DefaultStoragePricing',
  mixins: [ClientMixin, ModalActionsMixin, VersionedPriceList],
  components: {
    'default-storage-pricing-edit-modal': EditModal,
    'default-storage-pricing-create-modal': CreateModal,
  },
  data() {
    return {
      editModal: false,
      createModal: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      selected: null,
    }
  },

  computed: {
    headers() {
      return tableHeaders
    },

    dataReady() {
      return !this.loading && this.getSelectedPriceListVersionId
    },

    defaultStoragePricingList() {
      const list = this.$store.getters['defaultStoragePricing/getAll']
      if (Array.isArray(list))
        return list.map(l => ({
          ...l,
          loading: this.isDeleteLoading(l.id),
        }))
      else return []
    },

    items() {
      return this.defaultStoragePricingList.map(item => {
        return {
          ...item,
          price: this.formatPrice(item.price, 3),
          createdAt: this.formatDate(item.createdAt),
          updatedAt: this.formatDate(item.updatedAt),
        }
      })
    },

    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },

    meta() {
      return this.$store.getters['defaultStoragePricing/getMeta'] || { total: this.items.length }
    },

    url() {
      return '/storage-default-price-list/'
    },
  },

  methods: {
    fetch() {
      if (this.dataReady) {
        const { options } = this
        const { page } = options
        const params = {}
        const priceListVersion = this.getSelectedPriceListVersionId
        if (priceListVersion) params.priceListVersion = priceListVersion
        if (page) params.page = page
        this.$store.dispatch('defaultStoragePricing/fetchCollection', {
          params,
        })
      }
    },

    async onRemove() {
      await this.$store.dispatch('defaultStoragePricing/delete', this.selected.id).finally(() => {
        this.fetch()
        this.selected = ''
      })
    },

    isDeleteLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.url + id,
        method: 'DELETE',
      })
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
    getSelectedPriceListVersionId() {
      this.fetch()
    },
  },
}
</script>

<style lang="scss" scoped></style>
